// extracted by mini-css-extract-plugin
export var pwaBenefitWebsiteSection = "v_cv";
export var pwaCardRowIconsSection = "v_cy";
export var pwaCycleDevelopmentSection = "v_cw";
export var pwaCycleDevelopmentSection__testimonial = "v_cx";
export var pwaDomainExpertiseSection = "v_cC";
export var pwaFeaturedCaseSection = "v_cD";
export var pwaServiceAchievementsSection = "v_cJ";
export var pwaServiceClientsSection = "v_cK";
export var pwaServicesCompaniesSection = "v_cz";
export var pwaServicesCycleDevelopmentSection = "v_cB";
export var pwaSuccessStoriesSection = "v_cG";
export var pwaTechStackSection = "v_cF";
export var pwaWhyChooseSection = "v_cH";